export class AssessmentScoreUtil {
  static assessmentTotalScore(result) {
    const assessmentGrant = result;
    const keys = ['objectives',
      'alignsWithPepfar',
      'projectIsCostEffective',
      'alignsWithMission',
      'workPlan'];
    let score = 0;
    keys.forEach(key => {
      switch (assessmentGrant[key]) {
        case 'WEAK' :
          score += 2;
          break;
        case 'MODERATE' :
          score += 5;
          break;
        case 'STRONG':
          score += 10;
          break;
      }
    })
    return score;
  };
}
